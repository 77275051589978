import { render, staticRenderFns } from "./DetailBody.vue?vue&type=template&id=1d97b648&scoped=true&"
import script from "./DetailBody.vue?vue&type=script&lang=ts&"
export * from "./DetailBody.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./DetailBody.vue?vue&type=style&index=0&id=1d97b648&lang=css&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "1d97b648",
  null
  ,true
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VApp,VContainer,VDivider,VIcon,VMain,VTooltip})
