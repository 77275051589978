import Vue from "vue";
import App from "./App.vue";

import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import "./plugins/common";
import "./plugins/moment";

import VueCustomElement from "vue-custom-element";
import "document-register-element/build/document-register-element";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue);
Vue.use(VueCustomElement);

(App as any).options.store = store;
(App as any).options.vuetify = vuetify;
(App as any).options.i18n = i18n;

// Vue.customElement("irent-script", (App as any).options);

Vue.customElement("irent-script", (App as any).options, {
  shadow: true,
  beforeCreateVueInstance(root) {
    const rootNode = root.el.getRootNode();

    if (rootNode instanceof ShadowRoot) {
      root.shadowRoot = rootNode;
    } else {
      root.shadowRoot = document.head;
    }
    return root;
  },
});

setTimeout(() => {
  const irentScript = document.querySelector("irent-script");
  const vuetifyStyles = document.querySelector("#vuetify-theme-stylesheet");
  const styles = irentScript?.shadowRoot?.querySelectorAll("style");
  irentScript?.shadowRoot?.appendChild(vuetifyStyles?.cloneNode(true));
  document.querySelector("head").innerHTML += `
  <meta name="keywords" />
  <meta name="description" />
  <meta name="AUTHOR" />
  <meta name="COPYRIGHT" />
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;0,800;1,300;1,400;1,700;1,800;1,900">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css">`;

  if (window.location.hostname.includes("mallorcastays.com")) { 
    document.querySelector("head").innerHTML += `
    <style>
      html, body, #wrapwrap {
        overflow: unset !important;
      }
    </style>`;
  }
}, 1500);
