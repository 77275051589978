import { render, staticRenderFns } from "./Discounts.vue?vue&type=template&id=433837ee&scoped=true&"
import script from "./Discounts.vue?vue&type=script&lang=ts&"
export * from "./Discounts.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./Discounts.vue?vue&type=style&index=0&id=433837ee&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "433837ee",
  null
  ,true
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VHover,VIcon})
