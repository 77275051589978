


















































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, PropSync } from "vue-property-decorator";

import { IDates, ILocalGuests } from "@/interfaces/common";
import { IAccommodation, IPriceBreakDown } from "@/interfaces/accommodation";
import { IMapDays } from "@/views/AccoDetail.vue";
// import { mapState, mapMutations } from "vuex";
import GuestsField from "@/components/GuestsField.vue";
import ReservationDatesField from "@/components/ReservationDatesField.vue";
import { listStore } from "@/store/modules/list";

@Component({
  components: {
    GuestsField,
    ReservationDatesField,
  },
})
export default class BookingBox extends Vue {
  @Prop({
    type: Object,
    default: () => null,
  })
  readonly guests!: ILocalGuests | null;
  @Prop({
    type: Object,
    default: () => ({
      Begin: null,
      End: null,
    }),
  })
  readonly dates!: IDates;

  @PropSync("dates", { type: Object }) syncedDates!: IDates;

  @Prop({
    type: Object,
    required: true,
  })
  readonly accommodation!: IAccommodation;
  @Prop({
    type: Object,
    required: true,
  })
  readonly priceBreakDown!: IPriceBreakDown;
  @Prop({
    type: Object,
    default: () => ({}),
  })
  readonly days!: IMapDays;
  @Prop({
    type: Number,
    default: 0,
  })
  readonly totalRental!: number;
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly calculatingPrice!: boolean;

  get totalGuests() {
    let total = this.guests
      ? this.guests.GuestAdults +
      this.guests.GuestChildrens +
      this.guests.GuestInfants
      : 0;
    return total;
  }

  get favoritesAcco() {
    return listStore.favoritesAcco;
  }

  protected addOrRemoveFavoriteAcco(accoID: number) {
    return listStore.addOrRemoveFavoriteAcco(accoID);
  }

  protected handleInputDatesField(val: IDates) {
    this.syncedDates = val;
    this.$emit("update:dates", val);
    this.$emit("change", { dates: val, totalGuests: this.totalGuests });
  }
}
