

























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import moment from "moment";

import { IPaymentsSchedule } from "@/interfaces/common";
import { ILocalExtra } from "@/views/BookingProcess.vue";

@Component
export default class PaymentSchedule extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  readonly payments!: Array<IPaymentsSchedule>;
  @Prop({
    type: String,
    required: true,
  })
  readonly arrival!: string;
  @Prop({
    type: String,
    required: true,
  })
  readonly departure!: string;
  @Prop({
    type: Array,
    required: true,
  })
  readonly optionalExtras!: Array<ILocalExtra>;
  get paymentsList() {
    const now = moment();
    const arrival = moment(this.arrival);
    const departure = moment(this.departure);
    // Agrupamos por fechas los pagos
    let paymentsGroup = this.payments.reduce<{
      [date: string]: IPaymentsSchedule & { Text: string };
    }>((acc, current) => {
      const dateStr = current.ScheduledDate.substr(0, 10);
      if (acc[dateStr]) {
        acc[dateStr].Amount += current.Amount;
        acc[dateStr].ScheduledDate = dateStr;
      } else {
        acc[dateStr] = {
          Amount: current.Amount,
          ScheduledDate: dateStr,
          Text: "",
        };
      }

      // La fecha es hoy
      if (now.isSame(dateStr, "day")) {
        acc[dateStr].Text = this.$t("reservation.payOnBooking").toString();
      }
      // La fecha es igual a la de entrada
      else if (arrival.isSameOrAfter(dateStr, "day")) {
        const days = arrival.diff(dateStr, "day");
        acc[dateStr].Text =
          days > 0
            ? this.$t("reservation.payBeforeArrival", { days }).toString()
            : this.$t("reservation.payOnArrival").toString();
      }
      // La fecha es igual a la de salida
      else if (departure.isSame(dateStr, "day")) {
        acc[dateStr].Text = this.$t("reservation.payOnDeparture").toString();
      }
      return acc;
    }, {});
    // Luego acumulamos los offsets
    paymentsGroup = this.optionalExtras.reduce((acc, current) => {
      const offset = current.Extra.PaymentOffset ?? 0;
      let strDate: string;
      if (arrival.clone().subtract(Math.abs(offset), "days").isBefore(now)) {
        strDate = now.clone().utcOffset(0, true).toISOString(true).substr(0, 10);
      } else {
        strDate = arrival
          .clone()
          .subtract(Math.abs(offset), "days")
          .utcOffset(0, true)
          .toISOString(true)
          .substr(0, 10);
      }
      if (current.Amount * current.Value > 0) {
        // Si no existe la fecha, agregamos un nuevo calendario de pago
        if (!acc[strDate]) {
          acc[strDate] = {
            Amount: 0,
            ScheduledDate: strDate,
            Text: this.$t("reservation.payBeforeArrival", {
              days: Math.abs(offset),
            }).toString(),
          };
        }
        acc[strDate].Amount += current.Amount * current.Value;
      }
      return acc;
    }, paymentsGroup);
    // Lo ordenamos por fechas
    return Object.keys(paymentsGroup)
      .map((k) => paymentsGroup[k])
      .sort((a, b) => moment(a.ScheduledDate).diff(b.ScheduledDate, "days"));
  }
}
