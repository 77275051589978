











































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";

import { irentAPI } from "@/services/api";
import { authStore } from "@/store/modules/auth";

import { ICalculatedExtra, IDates, IExtra } from "@/interfaces/common";
import { IAccommodation, IPriceBreakDown } from "@/interfaces/accommodation";
import { IBooking } from "@/interfaces/occupancy";

import PersonalDetail, {
  IContactPersonalDetail,
} from "@/components/bookingProcess/PersonalDetail.vue";
import ExtrasIncluded from "@/components/bookingProcess/ExtrasIncluded.vue";
import OptionalExtras from "@/components/bookingProcess/OptionalExtras.vue";
import PaymentSchedule from "@/components/bookingProcess/PaymentSchedule.vue";
import TermsAndConditions from "@/components/bookingProcess/TermsAndConditions.vue";

import GuestsField from "@/components/GuestsField.vue";
import { ILocalGuests } from "@/interfaces/common";
import moment from "moment";
import vueHeadful from "vue-headful";

export interface ILocalExtra {
  Value: number;
  Amount: number;
  Extra: IExtra;
  Quantity: number;
}

@Component({
  components: {
    PersonalDetail,
    ExtrasIncluded,
    OptionalExtras,
    PaymentSchedule,
    TermsAndConditions,
    GuestsField,
    vueHeadful,
  },
})
export default class BookingProcess extends Vue {
  @Ref("personalDetail")
  readonly personalDetail!: PersonalDetail;

  @Prop({
    type: Number,
    required: true,
  })
  readonly accommodationId!: number;
  @Prop({
    type: String,
    required: true,
  })
  readonly arrival!: string;
  @Prop({
    type: String,
    required: true,
  })
  readonly departure!: string;
  @Prop({
    type: Boolean,
  })
  readonly isOption!: boolean | null;
  @Prop({
    type: Object,
  })
  readonly guestPropBP!: ILocalGuests | null;

  @Prop({
    type: Boolean,
  })
  readonly quote!: boolean | null;

  @Prop({
    type: Object,
  })
  readonly tenant!: any;

  @Prop({
    type: Object,
  })
  readonly priceBreakDown!: IPriceBreakDown | null;

  @Prop({
    type: Object,
  })
  readonly option!: any | null;

  payment_success = false;
  payment_attempt = false;
  is_quote = false;
  is_option = this.isOption;
  guestsLocal: ILocalGuests = {
    GuestAdults: 1,
    GuestInfants: 0,
    GuestChildrens: 0,
  };
  snackbar = false;
  snackbarText = "";

  get allCalculatedExtras() {
    const result = new Array<IExtra>();
    if (this.prices && this.prices.CalculatedExtras) {
      for (const extra of this.prices.CalculatedExtras) {
        // if (extra.PackageCalculationResults) {
        //   result.push(
        //     ...extra.PackageCalculationResults.map<IExtra>((x) => ({
        //       ...x.Extra,
        //       BookingIncluded: extra.Extra.BookingIncluded,
        //       Mandatory: extra.Extra.Mandatory,
        //       PriceIncluded: extra.Extra.PriceIncluded
        //     }))
        //   );
        // } else {
        extra.Extra.TotExtAmount = extra.Amount;
        result.push(extra.Extra);
        //}
      }
    }
    return result;
  }

  get extrasMandatoryFiltered() {
    const arr = this.allCalculatedExtras
      .filter(
        (extra) =>
          extra.Mandatory &&
          !extra.PriceIncluded &&
          !(extra.TotExtAmount === 0 && extra.Type !== 100 && extra.Type !== 14)
      )
      .slice(0)
      .sort((a, b) =>
        this.$common
          .toLangName(a.Description)
          .localeCompare(
            this.$common.toLangName(b.Description),
            this.$i18n.locale
          )
      )
      // .map((extra) => {
      //   extra.TotExtAmount =
      //     extra.CalculationType == 2
      //       ? extra.Amount * this.totalGuests
      //       : extra.Amount;
      //   return extra;
      // });
    return arr;
  }

  get extrasIncludedFiltered() {
    const arr = this.allCalculatedExtras
      .filter(
        (extra) =>
          extra.PriceIncluded || extra.BookingIncluded
      )
      .slice(0)
      .sort((a, b) =>
        this.$common
          .toLangName(a.Description)
          .localeCompare(
            this.$common.toLangName(b.Description),
            this.$i18n.locale
          )
      );
    return arr;
  }

  get guestItems() {
    return Array(this.accommodation?.MaxGuests)
      .fill(null)
      .map((_, i) => ({
        text: (i + 1).toString(),
        value: i + 1,
      }));
  }

  get totalRental() {
    return (
      (this.prices?.TotalRental ?? 0) +
      this.optionalExtras.reduce(
        (total, current) =>
          current.Value ? total + current.Amount * current.Value : total,
        0
      )
    );
  }

  get nextText() {
    switch (this.step) {
      case 1:
        return this.$t("common.continue");
      case 2:
        return this.is_option && !this.isQuote
          ? this.$t("reservation.next3b")
          : this.isConfirmingOption
            ? this.$t("reservation.next3Option")
            : this.$t("reservation.next3");
      case 3:
        return this.$t("reservation.printContract");
      default:
        return "";
    }
  }

  get isConfirmingOption() {
    return this.option != null;
  }

  accommodation: IAccommodation | null = null;
  reservationNumber = "";
  reservationFormUrl = "";
  reservationAgent = "";
  quoteNumber = "";
  step = 1;
  loading = true;
  prices: IPriceBreakDown | null = null;
  optionalExtras = new Array<ILocalExtra>();
  mandatoryExtrasAmount = 0;
  includedExtrasAmount = 0;
  damageDeposit = 0;
  paymentOption: "credit-card" | "bank-transfer" = "credit-card";
  isAcceptConditions = false;
  bookingLoader = false;
  bookingError = false;
  bankInformation = "";
  irentElementOffset = 0;
  get totalGuests() {
    if (this.guestsLocal) {
      return (
        this.guestsLocal.GuestAdults +
        this.guestsLocal.GuestChildrens +
        this.guestsLocal.GuestInfants
      );
    } else {
      return 0;
    }
  }
  async mounted() {
    window.onpopstate = () => {
      this.goToDetail();
    };
    this.irentElementOffset = this.$common.getTop(
      document.querySelector("irent-script") as HTMLElement
    );
    window.scrollTo(0, this.irentElementOffset);
    // Buscamos los detalles del alojamiento
    this.loading = true;
    this.accommodation = await this._getDetails();
    if (
      (this.accommodation?.AvailabilityStatus == 1 ||
        this.accommodation?.AvailabilityStatus > 5) &&
      !this.isConfirmingOption
    ) {
      this.snackbarText = this.$t(
        "bookings.titles.datesUnavailable"
      ).toString();
      this.snackbar = true;
      setTimeout(() => {
        this.goToDetail("detail-availability");
      }, 2000);
    }
    if (
      this.arrival == null ||
      this.departure == null ||
      this.arrival == "null" ||
      this.departure == "null"
    ) {
      this.goToDetail();
    }
    this.checkOnRequest({ onRequestPeriods: this.accommodation?.OnRequest });
    if (this.guestPropBP) {
      this.guestsLocal = this.guestPropBP;
    } else {
      this.guestsLocal.GuestAdults = this.accommodation?.MaxGuests ?? 1;
    }
    this.prices = await this._getPriceBreakDown({
      AccommodationId: this.accommodationId,
      Guests: this.totalGuests,
      Dates: {
        Begin: this.arrival,
        End: this.departure,
      },
    });

    if (this.prices?.CalculatedExtras) {
      this.prices.CalculatedExtras.forEach((item) => {
        if (item.Extra.PriceIncluded || item.Extra.BookingIncluded) {
          this.includedExtrasAmount += item.Amount;
        } else if (
          item.Extra.Mandatory &&
          !(
            item.Amount === 0 &&
            item.Extra.Type !== 100 &&
            item.Extra.Type !== 14
          )
        ) {
          this.mandatoryExtrasAmount += item.Amount;
        }
      });
    }

    this.damageDeposit = this.prices?.DamageDeposit ? this.prices?.DamageDeposit : 0;

    if (this.prices?.AvailableExtras) {
      this.prices.AvailableExtras = this.prices.AvailableExtras.filter(
        (el) => el != null
      );
      this.optionalExtras = this.sortOptionalExtras(
        this.prices.AvailableExtras
      );
    }
    this.bankInformation = await this._getBankInformation({
      AccoAdminId: this.accommodation?.Administrator.Id,
      WebsiteAgentId: authStore.administrator?.Agent.Id,
    });
    history.pushState(
      {},
      "Booking Process",
      `?linkBookingProcess=${this.accommodation?.Guid}&arrival=${this.arrival}&departure=${this.departure}&isOption=${this.is_option}`
    );
    this.loading = false;

    this.initPaymentListener();
  }

  //Calculo de precio al modificar cantidad de personas
  protected async calculatePrice() {
    this.loading = true;
    this.prices = await this._getPriceBreakDown({
      AccommodationId: this.accommodationId,
      Guests: this.totalGuests,
      Dates: {
        Begin: this.arrival,
        End: this.departure,
      },
    });
    this.loading = false;
  }

  /**
   * Ordena alfabeticamente los extras opcionales
   */
  protected sortOptionalExtras(extras: ICalculatedExtra[]) {
    const arr = extras
      .slice(0)
      .sort((a, b) =>
        this.$common
          .toLangName(a.Extra.Description)
          .localeCompare(
            this.$common.toLangName(b.Extra.Description),
            this.$i18n.locale
          )
      )

      // .map((x) => {
      //   x.Extra.PaymentOffset = Math.trunc(Math.random() * 15);
      //   return { ...x, Value: 0 };
      // }); // Offset random
      .map((x) => ({ ...x, Value: 0 }));
    return arr;
  }
  /**
   * Acciones para el boton de accion de "siguiente paso"
   */
  protected nextStep() {
    switch (this.step) {
      case 1:
        this.scrollTo("step2");
        setTimeout(() => {
          this.step++;
        }, 150);
        break;
      case 2:
        if (this.isQuote) {
          this.makeQuote();
        } else {
          this.makeBooking();
        }
        break;
      case 3:
        // Abre en nueva pestaña el documento de reserva
        window.open("https://" + this.reservationFormUrl, "_blank");
        break;
    }
  }

  protected tryAgain() {
    this.bookingError = false;
    this.step = 1;
  }
  /**
   * Realiza la reserva con los datos necesarios
   */
  protected async makeBooking() {
    const data = this.personalDetail.save();
    if (!data) {
      await this.scrollTo("step2");
      return;
    }
    if (!this.isAcceptConditions) {
      this.scrollTo("terms");
      this.$refs["termsComponent"].$refs.termsForm.resetValidation();
      setTimeout(() => {
        this.$refs["termsComponent"].$refs.termsForm.validate();
      }, 300);
      return;
    }
    const comment = data.Comment;
    data.Comment = undefined;
    this.bookingLoader = true;
    const result = await this._postMakeBooking({
      AccommodationId: this.accommodationId,
      Dates: {
        Begin: this.arrival,
        End: this.departure,
      },
      Guests: this.totalGuests,
      ContactTenant: data,
      ContactBillTo: data,
      TenantRemarks: comment, // (opcional)
      ReservationType: this.is_option ? "Option" : "Booking",
      // Mapeamos los extras opcionales que fueron marcados
      OptionalExtras: this.optionalExtras.reduce<{ [x: number]: number }>(
        (prev, curr) => {
          if (curr.Value) {
            prev[curr.Extra.Id] =
              typeof curr.Value == "boolean" ? curr.Quantity : curr.Value;
          }
          return prev;
        },
        {}
      ),
      OptionNumber: this.isConfirmingOption ? this.option.Number : "",
    });
    this.bookingLoader = false;
    this.step++;
    if (!result) {
      this.bookingError = true;
      return;
    }
    this.reservationNumber = result?.Number ?? "";
    this.reservationFormUrl = result?.ReservationFormUrl ?? "";
    this.reservationAgent = result?.Agent.Guid ?? "";
    if (!this.is_option && this.paymentOption == "credit-card")
      this.openPayment();
  }

  /**
   * Realiza un formulario con presupuesto
   */
  protected async makeQuote() {
    const data = this.personalDetail.save();
    if (!data) {
      this.scrollTo("step2");
      return;
    }
    if (!this.isAcceptConditions) {
      this.scrollTo("terms");
      this.$refs["termsComponent"].$refs.termsForm.resetValidation();
      setTimeout(() => {
        this.$refs["termsComponent"].$refs.termsForm.validate();
      }, 300);
      return;
    }
    const comment = data.Comment;
    data.Comment = undefined;
    this.bookingLoader = true;
    const result = await this._postMakeQuote({
      AccommodationId: this.accommodationId,
      Dates: {
        Begin: this.arrival,
        End: this.departure,
      },
      Guests: this.guestsLocal,
      GuestsTotal: this.totalGuests,
      ContactTenant: data,
      ContactBillTo: data,
      TenantRemarks: comment, // (opcional)
      ReservationType: this.is_option ? "Option" : "Booking",
      // Mapeamos los extras opcionales que fueron marcados
      OptionalExtras: this.optionalExtras.reduce<{ [x: number]: number }>(
        (prev, curr) => {
          if (curr.Value) {
            prev[curr.Extra.Id] =
              typeof curr.Value == "boolean" ? curr.Quantity : curr.Value;
          }
          return prev;
        },
        {}
      ),
    });
    this.bookingLoader = false;
    this.step++;
    if (!result) {
      this.bookingError = true;
      return;
    }
    this.quoteNumber = result;
  }

  openPayment() {
    this.$common.openProcessPayment({
      code: this.accommodation?.Guid,
      reservationNumber: this.reservationNumber,
      reservationAgent: this.reservationAgent,
      amount: this.prices?.PaymentsSchedule.AllPayments[0].Amount,
      fee: 0,
    });
  }

  /***
   *  Inicia listener para escuchar la respuesta de la ventana donde se realiza un pago
   ***/
  initPaymentListener() {
    window.addEventListener(
      "message",
      (event) => {
        if (event.origin !== window.origin) return;
        switch (event.data) {
          case "OK":
            this.payment_attempt = true;
            this.payment_success = true;
            break;
          case "KO":
            this.payment_attempt = true;
            this.payment_success = false;
            break;
          default:
            break;
        }
      },
      false
    );
  }

  /**
   * Obtiene el detalle del alojamiento
   */
  private async _getDetails() {
    try {
      const res = await irentAPI.post<{
        Accommodation: IAccommodation;
        Status: number;
      }>("v1/Accommodation/Details", {
        AccommodationId: this.accommodationId,
        Guests: this.guestPropBP ? this.totalGuests : 1,
        AccommodationOptions: {
          Address: true,
          OnRequest: true,
          Rates: true,
        },
        IncludePrices: true,
        Dates: {
          Begin: this.arrival,
          End: this.departure,
        },
        Currency: authStore.currency,
        Languages: [authStore.language],
        WebsiteId: authStore.websiteId,
      });
      res.data.Accommodation.AvailabilityStatus = res.data.Status;
      return res.data.Accommodation;
    } catch (err) {
      console.warn("[POST][ACCOMMODATION_DETAIL]: ", err);
      return null;
    }
  }

  /***
   * Obtiene los precios del alojamiento y sus extras
   ***/
  private async _getPriceBreakDown(params: {
    AccommodationId: number;
    Guests: number;
    Dates: IDates;
  }) {
    const { AccommodationId, Guests, Dates } = params;
    try {
      const res = await irentAPI.post<IPriceBreakDown>(
        "/v1/Accommodation/PriceBreakDown",
        {
          AccommodationId,
          Guests,
          Dates,
          Languages: [authStore.language],
          Currency: authStore.currency,
          WebsiteId: authStore.websiteId,
        }
      );
      return res.data;
    } catch (err) {
      console.warn("[POST][PRICE_BREAK_DOWN]", err);
      return null;
    }
  }

  /**
   *
   */
  private async _postMakeBooking(params: {
    AccommodationId: number;
    Dates: IDates;
    ReservationType?: "Booking" | "Option";
    Guests: number;
    // BookingReferrerId,
    ContactTenant: IContactPersonalDetail;
    ContactBillTo: IContactPersonalDetail;
    ArrivalRemarks?: string; // (opcional)
    DepartureRemarks?: string; // (opcional)
    AdministratorRemarks?: string; // (opcional)
    AgentRemarks?: string; // (opcional)
    ContractRemarks?: string; // (opcional)
    TenantRemarks?: string; // (opcional)
    CalculatePurchase?: boolean;
    OptionalExtras: unknown; // {id extra opcional: cantidad}
    OptionNumber: string;
  }) {
    try {
      const res = await irentAPI.post<IBooking>("/v1/Reservation/MakeBooking", {
        AccommodationId: params.AccommodationId,
        Dates: params.Dates,
        ReservationType: params.ReservationType ?? "Booking",
        Guests: params.Guests,
        BookingAgentId: authStore.payloadTokenAdministrator.agentId, // Agent id
        // BookingReferrerId,
        UserContact: { Id: authStore.payloadTokenAdministrator.userId }, // User id
        ContactTenant: params.ContactTenant,
        ContactBillTo: params.ContactBillTo,
        ArrivalRemarks: params.ArrivalRemarks,
        DepartureRemarks: params.DepartureRemarks,
        AdministratorRemarks: params.AdministratorRemarks,
        AgentRemarks: params.AgentRemarks,
        ContractRemarks: params.ContractRemarks,
        TenantRemarks: params.TenantRemarks,
        CalculatePurchase: params.CalculatePurchase ?? true,
        CalculationLanguage: authStore.language,
        OptionalExtras: params.OptionalExtras,
        ConfirmingOption: params.OptionNumber != "",
        OptionNumber: params.OptionNumber,
      });

      return res.data;
    } catch (err) {
      console.warn("[POST][MAKE_BOOKING]", err);
      return null;
    }
  }

  /**
   *
   */
  private async _postMakeQuote(params: {
    AccommodationId: number;
    Dates: IDates;
    ReservationType?: "Booking" | "Option";
    GuestsTotal: number;
    Guests: ILocalGuests;
    // BookingReferrerId,
    ContactTenant: IContactPersonalDetail;
    ContactBillTo: IContactPersonalDetail;
    ArrivalRemarks?: string; // (opcional)
    DepartureRemarks?: string; // (opcional)
    AdministratorRemarks?: string; // (opcional)
    AgentRemarks?: string; // (opcional)
    ContractRemarks?: string; // (opcional)
    TenantRemarks?: string; // (opcional)
    CalculatePurchase?: boolean;
    OptionalExtras: unknown; // {id extra opcional: cantidad}
  }) {
    try {
      const res = await irentAPI.post<IBooking>("/v1/Reservation/MakeQuote", {
        ContactForm: {
          WebsiteId: authStore.websiteId,
          Language: authStore.language,
          Dates: params.Dates,
          Remarks: params.TenantRemarks,
          Guests: params.GuestsTotal,
          ...params.Guests,
          AccommodationId: params.AccommodationId,
          Email: params.ContactTenant.Mail1,
          Treatment: params.ContactTenant.Treatment,
          Name: params.ContactTenant.Name,
          Surname: params.ContactTenant.Surname,
          Telephone: params.ContactTenant.Telephone1,
          CellPgone: params.ContactTenant.Mobile1,
          Address: params.ContactTenant.Address.StreetName,
          PostalCode: params.ContactTenant.Address.Postcode,
          City: params.ContactTenant.Address.City.Name,
          Country: params.ContactTenant.Address.Country.Name,
        },

        AccommodationId: params.AccommodationId,
        Dates: params.Dates,
        ReservationType: params.ReservationType ?? "Booking",
        BookingAgentId: authStore.payloadTokenAdministrator.agentId, // Agent id
        // BookingReferrerId,
        UserContact: { Id: authStore.payloadTokenAdministrator.userId }, // User id
        ContactTenant: params.ContactTenant,
        CalculatePurchase: params.CalculatePurchase ?? true,
        CalculationLanguage: authStore.language,
        OptionalExtras: params.OptionalExtras,
      });

      return res.data;
    } catch (err) {
      console.warn("[POST][MAKE_BOOKING]", err);
      return null;
    }
  }

  private async _getBankInformation(params: {
    AccoAdminId: number;
    WebsiteAgentId: number;
  }) {
    try {
      const res = await irentAPI.post<string>("/v1/Website/BankInformation", {
        ...params,
      });
      return res.data;
    } catch (err) {
      console.warn("[POST][GET_BANK_INFORMATION]", err);
      return "";
    }
  }

  protected goToDetail(elementRef = "") {
    this.$common.to("AccoDetail", {
      accommodationId: this.accommodationId,
      scrollTo: elementRef,
    });
  }

  /***
   * Returns the quantity of extras selected
   ***/
  get optionalSelected() {
    return this.optionalExtras.reduce(
      (total, current) => (current.Value ? total + 1 : total),
      0
    );
  }

  public async scrollTo(ref: string) {
    const element = this.$refs[ref] as HTMLDivElement;
    const alertContainer = (this.$refs["alertContainer"] as Vue)
      .$el as HTMLElement;
    if (!element) return;
    const top =
      element.offsetTop +
      alertContainer.offsetTop +
      alertContainer.clientHeight;
    await this.$vuetify.goTo(this.irentElementOffset + top, {
      duration: 300,
      offset: 0,
      easing: "easeOutQuint",
    });
  }

  async wantOption() {
    await window.scrollTo(0, this.irentElementOffset);
    this.is_option = true;
    history.pushState(
      {},
      "Booking Process",
      `?linkBookingProcess=${this.accommodation?.Guid}&arrival=${this.arrival}&departure=${this.departure}&isOption=${this.is_option}`
    );
  }

  /***
   * Determines if the dates selected are on a request period
   * @param {Object} params - { onRequestPeriods[] --Array of OnRequest Periods}
   * ***/
  checkOnRequest(params: {
    onRequestPeriods: Array<{ Begin: string; End: string }> | undefined;
  }) {
    params.onRequestPeriods?.some((period) => {
      if (
        moment(this.arrival).isBetween(
          moment(period.Begin),
          moment(period.End)
        ) ||
        moment(this.departure).isBetween(
          moment(period.Begin),
          moment(period.End)
        )
      ) {
        this.is_quote = true;
        return true;
      }
    });
  }

  get isQuote() {
    return this.quote || this.is_quote;
  }
}
