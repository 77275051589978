var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',[_c('v-container',{staticClass:"pa-xl-0 pa-lg-0"},[_c('div',{ref:"detail-description",attrs:{"id":"detail-description"}},[_c('h3',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$tc( ("listResults.gridList.accommodationsType." + (_vm.accommodation.Type)), 1 )))+" "+_vm._s(_vm.accommodation.Name)+" in "+_vm._s(_vm.accommodation.Address.City.Name)+" ")]),_c('h4',{staticClass:"pb-4"},[_vm._v(" "+_vm._s(_vm.$t("detail.description.description_h4", { guests: _vm.accommodation.MaxGuests, bedrooms: _vm.bedroomsCount, bathrooms: _vm.bathroomsCount, }))+" ")]),(_vm.accommodation.Descriptions)?_c('div',{staticClass:"detail-description detail-description-short mb-5"},[_c('div',{class:_vm.readMoreActivated ? '' : 'read-more',domProps:{"innerHTML":_vm._s(_vm.readMoreActivated
            ? _vm.$common.toLangName(_vm.accoDescription)
            : _vm.$common.toLangName(_vm.accoDescription).slice(0, 600)
            )}}),_c('div',{ref:"readMoreButton",staticClass:"w-100 text-center"},[_c('a',{staticClass:"link",on:{"click":_vm.activateReadMore}},[_vm._v(" "+_vm._s(_vm.readMoreActivated ? _vm.$t("common.readless") : _vm.$t("common.readmore"))+" ")])])]):_vm._e(),(_vm.accommodation.DamageDeposit)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("detail.description.damage_deposit", { currency: "€", deposit: _vm.accommodation.DamageDeposit, }))+" ")]):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.$t("detail.description.identification_number", { identificationNumber: _vm.accommodation.TourismRegistrationCode, }))+" ")])]),_c('div',{ref:"detail-facilities",attrs:{"id":"detail-facilities"}},[_c('v-divider',{staticClass:"ma-5"}),_c('h3',{staticClass:"pb-4"},[_vm._v(_vm._s(_vm.$t("detail.navbar.facilities")))]),_c('ul',{staticClass:"row bullets"},[_vm._l((_vm.filteredFacilities),function(facility,index){return [(!facility.text.includes('ParkingQuantity'))?_c('li',{key:index,staticClass:"col-12 col-sm-6 col-md-4 col-xl-4 pa-1"},[_c('v-icon',{staticClass:"px-0",attrs:{"color":"primary"}},[_vm._v("mdi-circle-double")]),_vm._v(" "+_vm._s(_vm.facilityNameAndDescription(facility))+" ")],1):_vm._e()]})],2),_c('div',{staticClass:"row detail-icons pa-5 d-none"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"primary rounded-lg mr-2 mb-2"},on),[_vm._v(" "+_vm._s(_vm.accommodation.MaxGuests)+" ")])]}}])},[_vm._v(" "+_vm._s(_vm.$common.capitalize(_vm.$tc("common.guest", 2)) + ": " + _vm.accommodation.MaxGuests)+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"primary rounded-lg mr-2 mb-2"},on),[_vm._v(" "+_vm._s(_vm.accommodation.Floors.length)+" ")])]}}])},[_vm._v(" "+_vm._s(_vm.$common.capitalize(_vm.$tc("detail.facilities.TotalFloors", 2)) + ": " + _vm.accommodation.Floors.length)+" ")]),_vm._l((_vm.filteredFacilities),function(facility,i){return [(_vm.$common.iconFromList(facility.text))?_c('v-tooltip',{key:i,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"primary rounded-lg mr-2 mb-2"},on),[_vm._v(" "+_vm._s(_vm._f("iconFromList")(facility.text))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$tc(("detail.facilities." + (facility.text)), 2)))+" ")]):_vm._e()]})],2)],1),(_vm.days)?_c('div',{ref:"detail-availability",attrs:{"id":"detail-availability"}},[_c('v-divider',{staticClass:"ma-5"}),_c('h3',{staticClass:"pb-4"},[_vm._v(_vm._s(_vm.$t("detail.navbar.availability")))]),_c('booking-calendar',{attrs:{"months":15,"days":_vm.days},on:{"input":function($event){return _vm.$emit('bookingnow')}},model:{value:(_vm.syncedDates),callback:function ($$v) {_vm.syncedDates=$$v},expression:"syncedDates"}})],1):_vm._e(),_c('div',{ref:"detail-map"}),_c('div',[_c('v-divider',{staticClass:"ma-5"}),(_vm.accommodation.Address.Coordinates &&
          _vm.accommodation.Address.Coordinates.Lat &&
          _vm.accommodation.Address.Coordinates.Lng
          )?_c('acco-map',{attrs:{"coordinates":_vm.accommodation.Address.Coordinates}}):_vm._e()],1),(_vm.accommodation.GuestsRatings.length)?_c('div',{ref:"detail-reviews",attrs:{"id":"detail-reviews"}},[_c('v-divider',{staticClass:"ma-5"}),_c('reviews',{attrs:{"reviews":_vm.accommodation.GuestsRatings,"guests-ratings-summary":_vm.accommodation.GuestsRatingsSummary,"ratings-summary":_vm.accommodation.RatingsSummary}})],1):_vm._e(),(_vm.accommodation.Extras)?_c('div',{ref:"detail-prices"},[_c('v-divider',{staticClass:"ma-5"}),_c('prices',{attrs:{"days":_vm.days,"extras":_vm.accommodation.Administrator.Extras.concat( _vm.accommodation.Extras ),"particularity":_vm.particularities}})],1):_vm._e(),_c('div',{ref:"detail-contact"},[_c('v-divider',{staticClass:"ma-5"}),_c('contact-form',{attrs:{"guests":_vm.guests,"dates":_vm.dates,"maxGuests":_vm.accommodation.MaxGuests,"days":_vm.days,"accoId":_vm.accommodation.Id}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }