

























































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";

import { IDates } from "@/interfaces/common";
import { IMapDays, MapDayType } from "@/views/AccoDetail.vue";

export interface IDay {
  day: string;
  indexcal: number;
  indexweek: number;
  indexday: number;
}

export interface IDayState {
  arrival: string;
  departure: string;
  selected?: unknown;
  over?: unknown;
  disabled?: boolean;
}

export interface IWeek {
  s: string;
}

export interface ICalendarDay {
  day: string;
  numberDay: string;
  minimunStay: number;
  states: IDayState | null;
}

export interface ICalendar {
  month: string;
  year: number;
  weeks: ICalendarDay[][];
}

@Component
export default class BookingCalendar extends Vue {
  @Prop({
    type: Object,
    default: () => ({
      Begin: null,
      End: null,
    }),
  })
  readonly value!: IDates;
  @Prop({
    type: Number,
    default: 15,
  })
  readonly months!: number;
  @Prop({
    type: Object,
    default: () => ({}),
  })
  readonly days!: IMapDays;
  @Prop({
    type: Array,
    default: () => moment.weekdaysMin(),
  })
  readonly weekdaysMin!: string[];

  calendar = new Array<ICalendar>();
  arrival: IDay | null = null;
  departure: IDay | null = null;
  previewDeparture: IDay | null = null;
  daysWithPrices = new Array<unknown>();
  changeDays = new Array<unknown>();
  lastDayHovered: IDay | null = null;

  @Watch("value", { immediate: true })
  protected watchValue(val: IDates) {
    if (this.arrival?.day == val.Begin && this.departure?.day == val.End)
      return;
    // Borramos si existe algo seleccionado
    if (this.arrival?.day) {
      this._validateDeparture({
        from: this.arrival,
        to: this.departure?.day ? this.departure : this.previewDeparture,
      });
      this._resetDays();
      this.$forceUpdate();
    }
    this._clickRangeDates(val);
  }

  @Watch("days")
  protected watchDays(val: IMapDays) {
    this.calendar.length = 0;
    this._initCalendar(val);
    this._clickRangeDates(this.value);
  }

  protected mounted() {
    this._initCalendar(this.days);
    this._clickRangeDates(this.value);
  }

  /**
   * Obtiene todos los posibles estados del dia
   */
  protected initStates(dayName = "", days: IMapDays, firstDay: moment.Moment) {
    const states: IDayState = {
      arrival: "",
      departure: "",
    };
    // Si está antes de la fecha de mañana, entonces no esta disponible
    if (firstDay.isAfter(dayName)) {
      states.arrival = "unavailable";
      states.departure = "unavailable";
    }
    // Si no existe el dia mapeado, es que no tiene precio, por lo tanto es precio a consultar
    else if (!days[dayName] || days[dayName].price == null) {
      states.arrival = "pricesOnRequest";
      states.departure = "pricesOnRequest";
    }
    // Si no tiene ni arribo ni salida, entonces es un dia disponible
    else if (!days[dayName].arrival && !days[dayName].departure) {
      states.arrival = days[dayName].price ? "available" : "pricesOnRequest";
      states.departure = days[dayName].price ? "available" : "pricesOnRequest";
    }
    // Disponibilidades generales
    else {
      const availability: { [k in MapDayType]: string } = {
        reservation: "unavailable",
        onDemandPeriods: "availableOnRequest",
        changeDay: "changeOverDay",
        pricesOnRequest: "pricesOnRequest",
        available: "available",
      };

      if (days[dayName].arrival?.type == days[dayName].departure?.type) {
        const type = days[dayName]?.arrival?.type ?? "available";
        // Si el tipo es "dia de cambio", procedemos a definir sus dos estados
        if (type == "changeDay") {
          states.arrival = "changeOverDay";
          states.departure = "changeOverDay";
          let dayIsoWeek: number = moment(dayName).isoWeekday();
          dayIsoWeek = dayIsoWeek === 7 ? 0 : dayIsoWeek; // Change Sunday (7) to 0
          if (dayIsoWeek != days[dayName].changeDay) {
            states.disabled = true;
          }
        }
        // Sino, Procedemos a completar el estado general del dia
        else {
          states.arrival = availability[type];
          states.departure = availability[type];
        }
      } else {
        const arrivalType =
          days[dayName].arrival?.type ??
          (days[dayName].price ? "available" : "pricesOnRequest");
        states.arrival = availability[arrivalType];
        const departureType =
          days[dayName].departure?.type ??
          (days[dayName].price ? "available" : "pricesOnRequest");
        states.departure = availability[departureType];
      }
    }
    // Si es el mismo dia, la salida no estara disponible
    const sameDay = firstDay.isSame(dayName, "day");
    if (sameDay) {
      states.departure = "unavailable";
    }

    return states;
  }
  /**
   * Selecciona intervalo de fechas segun lo clickeado
   */
  protected clickDay(
    day: ICalendarDay,
    indexcal: number,
    indexweek: number,
    indexday: number,
    disableInput?: boolean
  ): void {
    // Comprobamos que no este seleccionado ningun arrivo
    if (!this.arrival?.day) {
      if (day.states?.arrival == "unavailable") return;
      this.arrival = {
        day: day.day,
        indexcal,
        indexweek,
        indexday,
      };
      // day.states.over = "arrival";
      this._overDay(day, indexcal, indexweek, indexday, true);
    }
    // Si el dia seleccionado es igual al dia de arrivo, no hacemos nada
    else if (day.day == this.arrival.day) {
      return;
    }
    // Si el dia de salida no esta seleccionado y no es anterior al arrivo,
    // procedemos a rellenar
    else if (
      !this.departure?.day &&
      !moment(day.day).isBefore(this.arrival.day)
    ) {
      // Borramos lo previamente seleccionado
      if (this.previewDeparture?.day) {
        this._validateDeparture({
          from: this.arrival,
          to: this.previewDeparture,
        });
      }
      // Seleccionamos la entrada y salida
      const to = {
        day: day.day,
        indexcal,
        indexweek,
        indexday,
      };
      this.departure = this._validateDeparture({
        from: this.arrival,
        to: to,
        selected: true,
        over: false,
      });
      // De haber un dia no disponible, reseteamos y seleccionamos este dia como inicio
      if (this.departure?.day != to.day) {
        this.departure = to;
        return this.clickDay(day, indexcal, indexweek, indexday);
      }
      // De lo contrario, abrimos el dialogo de reserva
      else if (!disableInput) {
        this.$emit("input", {
          Begin: this.arrival.day,
          End: this.departure.day,
          BookingType: day.states?.departure,
        });
      }
    }
    // Resetea los dias y el rellenado
    else {
      this._validateDeparture({
        from: this.arrival,
        to: this.departure?.day ? this.departure : this.previewDeparture,
      });
      this._resetDays();
      return this.clickDay(day, indexcal, indexweek, indexday);
    }
    // Se hace un actualizacion forzada ya que no toma el cambio del dia
    this.$forceUpdate();
  }

  /**
   * Obtiene el titulo a mostra cuando el cursor pasa sobre el dia
   */
  protected titleByDayStates(params: {
    day: string;
    states: IDayState | null;
  }) {
    const { day, states } = params;
    if (states?.arrival == "unavailable") {
      return this.$t("detail.prices.textDateNotAvailable");
    } else if (
      this.days[day]?.changeDay &&
      states?.arrival == "changeOverDay"
    ) {
      if (moment(day).isoWeekday() == this.days[day]?.changeDay) {
        return this.$t("detail.prices.textChangeoverDay");
      }
    }
    return this.$t("detail.prices.textDateAvailable");
  }
  /**
   * Obtiene el arreglo con las clases correspondientes segun los estados del dia
   */
  protected cssByDayStates(params: {
    arrival: string;
    departure: string;
    disabled?: boolean;
    over: string;
    selected: string;
  }) {
    const { arrival, departure, disabled, over, selected } = params;
    const css = [];

    if (over) {
      if (over == "arrival") {
        css.push("selectedDateOver-arrival", `${departure}-departure`);
      } else if (over == "departure") {
        css.push("selectedDateOver-departure", `${arrival}-arrival`);
      } else {
        css.push("selectedDateOver");
      }
    } else if (selected) {
      if (selected == "arrival") {
        css.push("selectedDate-arrival", `${departure}-departure`);
      } else if (selected == "departure") {
        css.push("selectedDate-departure", `${arrival}-arrival`);
      } else {
        css.push("selectedDate");
      }
    } else if (arrival == departure) {
      css.push(arrival);
    } else {
      css.push(`${arrival}-arrival`, `${departure}-departure`);
    }

    if (disabled) {
      css.push("day-disabled");
    }

    return css;
  }

  /**
   * Inicializa el calendario
   */
  private _initCalendar(mappedDays: IMapDays) {
    // Mapeamos las reservaciones para un facil acceso
    const firstDay = moment().startOf("day").add(1, "day");
    // Comenzamos a armar los calendarios dependiendo la cantidad establecida en la propiedad months
    for (let indexMonth = 0; indexMonth < this.months; indexMonth++) {
      // Obtenemos el primer dia del mes
      const firstDayMonthDate = firstDay
        .clone()
        .startOf("month")
        .add(indexMonth, "month");
      const monthName = firstDayMonthDate.format("MMMM");
      // Obtenemos el primer dia a visualizar
      const date = firstDayMonthDate
        .clone()
        .subtract(firstDayMonthDate.isoWeekday(), "day");
      // Obtenemos las semanas
      const weeks = new Array<ICalendarDay[]>(6);
      for (let indexWeek = 0; indexWeek < weeks.length; indexWeek++) {
        const weekDate = date.clone().add(indexWeek, "week");
        const days = new Array<ICalendarDay>(7);
        weeks[indexWeek] = days;
        // Obtenemos los dias correspondientes
        for (let indexDay = 0; indexDay < days.length; indexDay++) {
          const dayDate = weekDate.clone().add(indexDay, "day");
          const dayName = dayDate.toISOString(true).substring(0, 10);
          const isMonthEqual = firstDayMonthDate.month() == dayDate.month();
          // Completamos el dia
          days[indexDay] = {
            day: isMonthEqual ? dayName : "",
            numberDay: isMonthEqual ? dayDate.format("D") : "",
            minimunStay: 0, // TODO: poner el valor correcto,
            states: isMonthEqual
              ? this.initStates(dayName, mappedDays, firstDay)
              : null,
          };
        }
      }
      // Obtenemos el año
      const year = date.year();
      // Agregamos el item
      this.calendar.push({
        month: monthName,
        weeks,
        year,
      });
    }
  }
  /**
   * Clickea las dos fechas para que se marque en el calendario
   */
  private _clickRangeDates(dates: IDates) {
    const { Begin, End } = dates;
    if (Begin && End) {
      // Buscamos la fecha de entrada y de salida
      for (let ical = 0; ical < this.calendar.length; ical++) {
        for (let iweek = 0; iweek < this.calendar[ical].weeks.length; iweek++) {
          for (
            let iday = 0;
            iday < this.calendar[ical].weeks[iweek].length;
            iday++
          ) {
            const item = this.calendar[ical].weeks[iweek][iday];
            if (item.day == Begin) {
              this.clickDay(item, ical, iweek, iday, true);
            } else if (item.day == End) {
              this.clickDay(item, ical, iweek, iday, true);
              return;
            }
          }
        }
      }
    }
  }
  /**
   * Valida la fechas seleccionadas considerando que en el medio no haya ninguna fecha no disponible
   * @returns Fecha de salida o de corte
   */
  private _validateDeparture(params: {
    from: IDay;
    to: IDay | null;
    selected?: boolean;
    over?: boolean;
  }): IDay {
    const { from, to, selected, over } = params;
    if (!to)
      return {
        day: from.day,
        indexcal: from.indexcal,
        indexweek: from.indexweek,
        indexday: from.indexday,
      };
    const beginDate = moment(from.day);
    const endDate = moment(to.day);
    const daysDiff = endDate.diff(beginDate, "day");
    // Utilizamos indices auxiliares
    let i = 0;
    let iday = from.indexday;
    let iweek = from.indexweek;
    let ical = from.indexcal;
    // Recorremos los dias a rellenar
    while (i <= daysDiff) {
      // Reseteamos el dia
      const days = this.calendar[ical].weeks[iweek];
      if (iday >= days.length) {
        iday = 0;
        iweek++;
      }
      // Reseteamos la semana
      const weeks = this.calendar[ical].weeks;
      if (iweek >= weeks.length) {
        iweek = 0;
        ical++;
      }
      // El dia tiene que ser valido para avanzar al siguiente indice
      const day = this.calendar[ical].weeks[iweek][iday];
      const type =
        from.day == day.day
          ? "arrival"
          : to.day == day.day
          ? "departure"
          : "all";
      if (day.day && day.states) {
        day.states.selected = selected ? type : null;
        day.states.over = over ? type : null;
        if (day.states.arrival == "unavailable") {
          day.states.selected = selected ? "departure" : null;
          day.states.over = over ? "departure" : null;
          iday++;
          break;
        }
        i++;
      }
      iday++;
    }
    // Devolvemos en el dia que se detuvo el rellenado
    return {
      day: this.calendar[ical].weeks[iweek][iday - 1].day,
      indexcal: ical,
      indexweek: iweek,
      indexday: iday - 1,
    };
  }
  /**
   * Evento de over en un dia
   */
  private _overDay(
    day: ICalendarDay,
    indexcal: number,
    indexweek: number,
    indexday: number,
    ignoreSameDay = false
  ) {
    // Si no esta seleccionado el arrivo, no hacemos nada
    if (!this.arrival?.day) return;
    // Si esta seleccionado la salida, no hacemos nada
    if (this.departure?.day) return;
    // Si el dia seleccionado es igual al dia de arrivo, no hacemos nada
    if (!ignoreSameDay && day.day == this.arrival.day) return;
    // Si el dia es anterior al arrivo, no hacemos nada
    if (moment(day.day).isBefore(this.arrival.day)) return;
    // Deseleccionamos lo previamente seleccionado
    if (this.previewDeparture?.day) {
      this._validateDeparture({
        from: this.arrival,
        to: this.previewDeparture,
      });
    }
    // Definimos el nuevo valor a la salida
    const minimumDate = moment(this.arrival.day).add(
      this.days[this.arrival.day]?.changeDay
        ? 7
        : this.days[this.arrival.day]?.minimumStay ?? 1,
      "day"
    );
    let minimunDateKey = minimumDate.toISOString(true).substring(0, 10);
    // Comprobamos que la fecha de salida no este parada sobre un cambio de dia no correspondiente
    if (
      this.days[minimunDateKey]?.changeDay &&
      this.days[minimunDateKey].changeDay != minimumDate.isoWeekday()
    ) {
      const changeDay = this.days[minimunDateKey].changeDay ?? 0;
      const weekDay = minimumDate.isoWeekday();
      minimumDate.add(changeDay - weekDay, "day");
      minimunDateKey = minimumDate.toISOString(true).substring(0, 10);
    }

    const to = moment(day.day).isBefore(minimumDate)
      ? { day: minimunDateKey, indexcal: -1, indexweek: -1, indexday: -1 }
      : {
          day: day.day,
          indexcal,
          indexweek,
          indexday,
        };
    // Rellenamos las fechas seleccionadas
    this.previewDeparture = this._validateDeparture({
      from: this.arrival,
      to,
      over: true,
    });
    // Se hace un actualizacion forzada ya que no toma el cambio del dia
    this.$forceUpdate();
  }
  /**
   * Resetea los valores de los dias seleccionados en el calendario
   */
  private _resetDays() {
    // Reseteamos los valores y volvemos a invocar la funcion para que empiece de cero
    this.arrival = null;
    this.departure = null;
    this.previewDeparture = null;
  }
}
