



































































































































































import {
  IReview,
  RatingsSummary,
  GuestsRatingsSummary,
} from "@/interfaces/accommodation";
import { irentAPI } from "@/services/api";
import { authStore } from "@/store/modules/auth";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  filters: {
    date(data) {
      if (data) {
        return data.toLocaleString(authStore.language, {
          month: "long",
          year: "numeric",
        });
      } else {
        return "not date";
      }
    },
  },
})
export default class Reviews extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  readonly reviews!: IReview[];

  @Prop({
    type: Object,
    required: true,
  })
  readonly guestsRatingsSummary!: GuestsRatingsSummary;

  @Prop({
    type: Object,
    required: true,
  })
  readonly ratingsSummary!: RatingsSummary;

  defaultLimit = 5;
  limit = this.defaultLimit;
  countries: {
    country_id: number;
    country_name: string;
  }[] = [];
  get reviewsComments() {
    let result = new Array<IReview>();
    result = this.reviews.filter((rev) => rev.Comments != "");
    result.sort(
      (a, b) => new Date(b.Time).getTime() - new Date(a.Time).getTime()
    );
    return result.slice(0, this.limit);
  }

  get commentsNumber() {
    return this.reviews.filter((rev) => rev.Comments != "").length;
  }
  async mounted() {
    let res = await irentAPI.get(
      `/v1/Website/CountriesList?Language=${authStore.language}`
    );
    this.countries = res.data;
  }
  countryName(idPais: number) {
    if (this.countries.length > 1)
      return this.countries.find((el) => el.country_id == idPais)?.country_name;
    return "a";
  }
  loadMoreComments() {
    this.limit++;
  }
}
