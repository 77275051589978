export const LANGUAGES: { [locale: string]: string } = {
  es: "Spanish",
  en: "English",
  nl: "Dutch",
  fr: "French",
  de: "German",
};

export const POOL_CLASS: { [typeShape: number]: string } = {
  0: "No",
  1: "Private",
  2: "Communal",
};

export const POOL_SHAPE: { [typeShape: number]: string } = {
  0: "OtherShape",
  1: "Round",
  2: "Kidney",
  3: "Rectangular",
  4: "Lagoon",
  5: "Square",
  6: "Oval",
};
export const PARKING_TYPE: { [type: number]: string } = {
  0: "PrivateCoveredParking",
  1: "PrivateEnclosedCoveredParking",
  2: "CommunalEnclosedCoveredParking",
  3: "CommunalCoveredParking",
  4: "PublicCoveredParking",
  5: "PrivateOpenAirParking",
  6: "PrivateEnclosedOpenAirParking",
  7: "CommunalEnclosedOpenAirParking",
  8: "CommunalOpenAirParking",
  9: "PublicOpenAirParking",
  10: "PrivateGarage",
  11: "CommunalGarage",
  12: "PublicGarage",
};

export const INTERNET_TYPE: { [type: number]: string } = {
  0: "No",
  1: "ADSL",
  2: "Ethernet",
  3: "WiFi",
  4: "Mobile",
  5: "Optical Fiber",
};

export const ICON_LIST: { [x: string]: string } = {
  Pets: "mdi-paw",
  MaidService: "mdi-vacuum",
  Laundry: "mdi-washing-machine",
  Bedlinen: "mdi-hanger",
  WheelChair: "mdi-wheelchair-accessibility",
  NoSmoking: "mdi-smoking-off",
  TennisCourt: "mdi-tennis",
  JacuzziOutdoor: "mdi-hot-tub",
  Sauna: "mdi-hot-tub",
  FitnessArea: "mdi-dumbbell",
  ParkingType: "mdi-parking",
  GardenWithLawn: "mdi-grass",
  GardenWithGravel: "mdi-flower",
  GardenWithTrees: "mdi-tree-outline",
  ResortAccommodation: "mdi-home-group",
  GolfResort: "mdi-golf",
  PoolClass: "mdi-pool",
  kitchen: "mdi-stove",
  Barbecue: "mdi-grill-outline",
  OutdoorDining: "mdi-outdoor-lamp",
  AirConditioning: "mdi-air-conditioner",
  Fireplace: "mdi-fireplace",
  InternetType: "mdi-web",
  CableTV: "mdi-television",
  Sea: "mdi-waves",
  Mountain: "mdi-image-filter-hdr",
  Beach: "mdi-beach",
  Lake: "mdi-wave",
  SeaRiverLake: "mdi-shore",
};
