
























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { authStore } from "@/store/modules/auth";
import { routerStore } from "@/store/modules/router";
import { settingStore } from "@/store/modules/setting";

import Home from "@/views/Home.vue";
import ListResults from "@/views/ListResults.vue";
import AccoDetail from "./views/AccoDetail.vue";
import BookingProcess from "./views/BookingProcess.vue";
import SalesListResults from "@/views/SalesListResults.vue";
import SalesAccoDetail from "./views/SalesAccoDetail.vue";
import { irentAPI } from "./services/api";

@Component({
  components: {
    Home,
    ListResults,
    SalesListResults,
    AccoDetail,
    SalesAccoDetail,
    BookingProcess,
  },
})
export default class App extends Vue {
  @Prop({
    type: String,
  })
  readonly websiteCode!: string;
  // @Prop({
  //   type: String,
  // })
  // readonly agentKey!: string;
  @Prop({
    type: String,
    default: "EUR",
  })
  readonly currency!: string;
  @Prop({
    type: String,
    default: () => {
      const lang = navigator.language.split("-")[0];
      return lang.length > 0 ? lang : "en";
    },
  })
  readonly language!: string;

  @Prop({
    type: String,
  })
  readonly mapKey!: string;
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly winter!: boolean;
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly sales!: boolean;

  get tokenAdministrator() {
    return authStore.tokenAdministrator;
  }

  get authStatus() {
    return authStore.authStatus;
  }

  get routeName() {
    return routerStore.routeName;
  }

  get routeProps() {
    return routerStore.routeProps;
  }

  protected async mounted() {
    let queryStringOptions = new URLSearchParams(window.location.search);
    authStore.setParentUrl(queryStringOptions.get("url"));

    // Auth
    await authStore.setLanguage(this.language);
    await authStore.setCurrency(this.currency);
    await authStore.checkWebsiteCode(this.websiteCode);
    //await authStore.setAgentKey(this.agentKey);
    await authStore.setWinter(this.winter);
    await authStore.setSales(this.sales);
    // Settings
    await settingStore.setMapKey(this.mapKey);
    // Router
    if (queryStringOptions.get("link")) {
      const res = await irentAPI.post("v1/Accommodation/FindByGuid", {
        Guid: queryStringOptions.get("link"),
        WebsiteId: authStore.websiteId,
      });
      routerStore.setRoute({
        name: authStore.sales ? "SalesAccoDetail" : "AccoDetail",
        props: {
          accommodationId: res.data.AccommodationId,
          arrival: queryStringOptions.get("arrival"),
          departure: queryStringOptions.get("departure"),
          adults: queryStringOptions.get("adults"),
          children: queryStringOptions.get("children"),
          infants: queryStringOptions.get("infants"),
        },
      });
    } else if (queryStringOptions.get("linkBookingProcess")) {
      const res = await irentAPI.post("v1/Accommodation/FindByGuid", {
        Guid: queryStringOptions.get("linkBookingProcess"),
        WebsiteId: authStore.websiteId,
      });
      routerStore.setRoute({
        name: "BookingProcess",
        props: {
          accommodationId: res.data.AccommodationId,
          arrival: queryStringOptions.get("arrival"),
          departure: queryStringOptions.get("departure"),
          isOption: queryStringOptions.get("isOption") == "true" ? true : false,
        },
      });
    } else if (queryStringOptions.get("payment_result")) {
      let result = queryStringOptions.get("payment_result");
      try {
        if (result == "1") {
          window.parent.opener.postMessage("OK", window.location.href);
        } else {
          window.parent.opener.postMessage("KO", window.location.href);
        }
      } catch (error) {
        console.log("No se pudo enviar mensaje");
      }
      window.parent.close();
    } else if (authStore.sales) {
      routerStore.setRoute({
        name: "SalesListResults",
      });
    } else {
      routerStore.setRoute({
        name: "ListResults",
        props: {
          destId: queryStringOptions.get("destId"),
          destType: queryStringOptions.get("destType"),
          arrival: queryStringOptions.get("arrival"),
          departure: queryStringOptions.get("departure"),
          adults: queryStringOptions.get("adults"),
          children: queryStringOptions.get("children"),
          infants: queryStringOptions.get("infants"),
        },
      });
    }
    /*routerStore.setRoute({
      name: "BookingProcess",
      props: {
        accommodationId: 102209,
        arrival: "2022-06-01",
        departure: "2022-06-07",
      },
    }); */
  }
}
