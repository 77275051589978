





































































































































































































































































































import Vue from "vue";
import { Component, Prop, Ref } from "vue-property-decorator";
import moment from "moment";
import FsLightbox from "fslightbox-vue";

import { irentAPI } from "@/services/api";
import { authStore } from "@/store/modules/auth";
import { listStore } from "@/store/modules/list";

import { IOccupancyCalendar } from "@/interfaces/occupancy";
import {
  IAccommodation,
  IAccommodationOptions,
  IPriceBreakDown,
} from "@/interfaces/accommodation";
import { IDates, ILocalGuests } from "@/interfaces/common";

import DetailBody, { ISection } from "@/components/accoDetail/DetailBody.vue";
import BookingBox from "@/components/accoDetail/BookingBox.vue";
import BookingPriceDialog from "@/components/accoDetail/BookingPriceDialog.vue";
import vueHeadful from "vue-headful";
import CustomModal from "@/components/CustomModal.vue";

export type MapDayType =
  | "reservation"
  | "onDemandPeriods"
  | "changeDay"
  | "pricesOnRequest"
  | "available";

export interface IMapDay {
  price?: number;
  minimumStay?: number;
  arrival?: {
    type: MapDayType;
    end: string;
  };
  departure?: {
    type: MapDayType;
    begin: string;
  };
  changeDay?: number;
}

export interface IMapDays {
  [date: string]: IMapDay;
}

@Component({
  components: {
    FsLightbox,
    DetailBody,
    BookingBox,
    BookingPriceDialog,
    vueHeadful,
    CustomModal,
  },
})
export default class AccoDetail extends Vue {
  @Ref("detail-container")
  readonly container!: HTMLDivElement;
  @Ref("detail-body")
  readonly body!: DetailBody;
  @Ref("price-dialog")
  readonly bookingPriceDialog!: BookingPriceDialog;

  @Prop({
    type: Number,
    required: true,
  })
  accommodationId!: number;
  @Prop({
    type: String,
  })
  readonly arrival!: string;
  @Prop({
    type: String,
  })
  readonly departure!: string;

  @Prop({
    type: String,
  })
  readonly adults!: string;
  @Prop({
    type: String,
  })
  readonly children!: string;
  @Prop({
    type: String,
  })
  readonly infants!: string;

  images: string[] = [];

  get fillGallery() {
    if (this.accommodation?.Pictures) {
      return this.accommodation.Pictures.map(
        (element) =>
          "https://duzf08k2n1y1n.cloudfront.net/" +
          element.StandardVersion.S3Key.split("/")[1]
      );
    } else return [];
  }

  get totalGuests() {
    let total = this.guestsSearchLocal
      ? this.guestsSearchLocal.GuestAdults +
        this.guestsSearchLocal.GuestChildrens +
        this.guestsSearchLocal.GuestInfants
      : 0;
    return total;
  }

  get nights() {
    const { Begin, End } = this.datesSearchLocal;
    if (Begin && End) {
      return moment(End).diff(Begin, "days");
    }
    return 0;
  }

  get accoLocationString() {
    return ` ${this.accommodation?.Address.City.Name},${
      this.accommodation?.Address.Area.Name
    }, ${this.$common.toLangName(this.accommodation?.Address.Country.Names)}`;
  }

  fab = false;
  see = false;
  snackbar = false;
  snackbarText = "";
  accoUrl = "";
  datesSearchLocal = listStore.dates;
  guestsSearchLocal = listStore.guests;
  accommodation: IAccommodation | null = null;
  occupancyCalendar: IOccupancyCalendar | null = null;
  priceBreakDown?: IPriceBreakDown | null = {};
  days: IMapDays | null = null;
  pageSection = -1;
  sections: ISection[] = [
    {
      value: "description",
      icon: "mdi-text-box-multiple-outline",
      t: () => this.$t("detail.navbar.description"),
      disabled: false,
    },
    {
      value: "facilities",
      icon: "mdi-text-box-check-outline",
      t: () => this.$t("detail.navbar.facilities"),
      disabled: false,
    },
    {
      value: "availability",
      icon: "mdi-calendar-month-outline",
      t: () => this.$t("detail.navbar.availability_and_booking"),
      disabled: false,
    },
    {
      value: "map",
      icon: "mdi-map-legend",
      t: () => this.$t("detail.navbar.map"),
      disabled: false,
    },
    {
      value: "reviews",
      icon: "mdi-comment-quote-outline",
      t: () => this.$t("detail.navbar.reviews"),
      disabled: false,
    },
    {
      value: "prices",
      icon: "mdi-tag-multiple-outline",
      t: () => this.$t("detail.navbar.prices"),
      disabled: false,
    },
    {
      value: "contact",
      icon: "mdi-information-outline",
      t: () => this.$t("detail.navbar.contact"),
      disabled: false,
    },
  ];
  calculatingPrice = false;
  togglerGallery = false;
  brochureDialog = false;
  shareDialog = false;
  downloadingBrochure = false;
  thereAreComments = false;
  irentElementOffset = 0;
  protected async mounted() {
    window.onpopstate = () => {
      this.toListResults();
    };
    this.irentElementOffset = this.$common.getTop(
      document.querySelector("irent-script") as HTMLElement
    );
    this.$vuetify.goTo(this.irentElementOffset, {
      duration: 500,
      offset: 0,
      easing: "easeOutQuint",
    });
    if (this.arrival != null) {
      this.datesSearchLocal = { Begin: this.arrival, End: this.departure };
    }
    if (this.adults) {
      this.guestsSearchLocal = {
        GuestAdults: parseInt(this.adults),
        GuestChildrens: parseInt(this.children),
        GuestInfants: parseInt(this.infants),
      };
    }
    // Obtenemos los detalles del alojamiento
    const resDetail = await this._getAccommdationDetail({
      accommodationId: this.accommodationId,
      options: {
        Info: true,
        Description: {
          Short: true,
          Long: true,
          AutoShrot: true,
          AutoLong: true,
        },
        Address: true,
        SwimmingPool: true,
        AdministratorRatings: true,
        GuestsRatings: true,
        GuestsRatingsSummary: true,
        Services: true,
        CommunalFacilities: true,
        OutdoorFacilities: true,
        IndoorFacilities: true,
        Views: true,
        Distances: true,
        Floors: true,
        Pictures: true,
        Rates: true,
        PurchaseRates: true,
        Extras: true,
        OnRequest: true,
        // Owner: true,
        // Websites: true,
        AdministratorInfo: true,
        AdministratorExtras: true,
        AdministratorDiscounts: true,
        AdministratorParticularities: true,
        AdministratorPaymentSchedules: true,
      },
    });
    if (!resDetail) return;
    if (resDetail.AvailabilityStatus == 1 || resDetail.AvailabilityStatus > 5) {
      this.snackbarText = this.$t(
        "bookings.titles.datesUnavailable"
      ).toString();
      this.snackbar = true;
      this.datesSearchLocal.Begin = null;
      this.datesSearchLocal.End = null;
    }
    this.accommodation = resDetail;
    this.sections[4].disabled = this.reviewsDisabled;
    this.sections[5].disabled = this.pricesDisabled;

    let accoPath = `?link=${this.accommodation?.Guid}`;
    if (
      this.datesSearchLocal.Begin &&
      this.datesSearchLocal.Begin != "" &&
      this.datesSearchLocal.Begin != "null"
    )
      accoPath += `&arrival=${this.datesSearchLocal.Begin}&departure=${this.datesSearchLocal.End}`;
    if (this.guestsSearchLocal) {
      accoPath += `&adults=${this.guestsSearchLocal.GuestAdults}&children=${this.guestsSearchLocal.GuestChildrens}&infants=${this.guestsSearchLocal.GuestInfants}`;
    }
    history.pushState({}, "Detail Page", accoPath);
    this.accoUrl = authStore.parentUrl + accoPath;
    // Obtenemos el calendario del alojamiento
    const resOccupancy = await this._getOccupancyCalendar({
      accommodationIds: [this.accommodationId],
      occupancyCalendarOptions: {
        AccommodationDetails: false,
        AgentDetails: false,
        ReferrerDetails: false,
        TenantDetails: false,
        BillToDetails: false,
        ExtrasDetails: false,
      },
    });
    if (!resOccupancy) return;
    this.occupancyCalendar = resOccupancy;
    // Mapeamos los dias
    this.days = this._mapDays(this.accommodation, this.occupancyCalendar);
    await this.loadImages();
  }
  protected beforeDestroy() {
    window.onpopstate = null;
  }
  get reviewsDisabled() {
    //return this.accommodation?.GuestsRatings == undefined;
    return this.accommodation?.GuestsRatings == undefined || this.accommodation?.GuestsRatings.length == 0;
  }

  get pricesDisabled() {
    //return this.accommodation?.GuestsRatings == undefined;
    return this.accommodation?.Extras == undefined || this.accommodation?.Extras.length == 0;
  }
  protected async loadImages() {
    if (this.accommodation?.Pictures) {
      this.accommodation?.Pictures.forEach((element) => {
        let imageURL =
          "https://duzf08k2n1y1n.cloudfront.net/" +
          element.StandardVersion.S3Key.split("/")[1];
        this.images.push(imageURL);
      });
    } else {
      let imageExists = true;
      let index = 0;
      while (imageExists) {
        let num = "" + index;
        if (index < 10) {
          num = "0" + index;
        }
        index++;
        imageExists = await this.getPicture(num);
        if (imageExists) {
          this.images.push(
            `https://duzf08k2n1y1n.cloudfront.net/${this.accommodation?.Id}_${num}.jpg`
          );
        }
      }
    }
    this.images.sort();
  }
  async getPicture(num: string) {
    let result = await fetch(
      // `https://ik.imagekit.io/agrpv/${this.accommodation.Id}_${num}.jpg`
      `https://duzf08k2n1y1n.cloudfront.net/${this.accommodation.Id}_${num}.jpg`
    ).then((res) => {
      return res.status;
    });
    return result == 200;
  }

  scrollTo(num: number) {
    this.body.scrollToSection(num, this.bodyOffset);
  }

  get bodyOffset() {
    let result = (this.$refs["detail-container"] as HTMLElement).offsetTop;
    if (this.$vuetify.breakpoint.smAndDown) {
      result += (this.$refs["reservation-box"] as Vue).$el.clientHeight;
    }
    return result;
  }
  /***
   * Calcula el precio del alojamiento para el periodo y las personas seleccionadas
   ***/
  async calculatePrice(params: { dates: IDates; guests?: number }) {
    if (!this.days || !this.accommodation) return;
    const { Begin, End } = params.dates;
    // Consultamos si los dias tienen precios
    if (End && !this.days[End]?.price) {
      this.accommodation.TotalRental = 0;
      return;
    }
    // Consultamos los precios si cumplen con la estancia minima
    if (Begin && (this.days[Begin]?.minimumStay ?? 0) > this.nights) {
      this.accommodation.TotalRental = 0;
      return;
    }
    this.calculatingPrice = true;
    const result = await this._getPriceBreakDown({
      AccommodationId: this.accommodationId,
      Dates: params.dates,
      Guests: params.guests ?? 1,
    });
    this.calculatingPrice = false;
    if (!result) return;
    this.priceBreakDown = result;
    this.accommodation.TotalRental = result.TotalRental;
    this.accommodation = {
      ...this.accommodation,
    };
  }

  /**
   * Indica la apertura del dialogo de reservacion y calculo de precio
   */
  protected async openReservationDialog(params: {
    dates: IDates;
    totalGuests: number;
    guests: ILocalGuests;
  }) {
    const { dates, guests, totalGuests } = params;
    this.datesSearchLocal = dates;
    if (!dates || !dates.Begin || !dates.End) return;

    await this.calculatePrice({ dates, guests: totalGuests });
    this.bookingPriceDialog.openDialog({
      begin: dates.Begin,
      end: dates.End,
      totalGuests,
      guests,
      priceBreakDown: this.priceBreakDown,
    });
  }

  /***
   * Mapea todos los dias, dejando informacion importante por cada dia
   ***/
  private _mapDays(
    accommodation: IAccommodation,
    occupancyCalendar: IOccupancyCalendar
  ) {
    const mappedDays: IMapDays = {};
    const firstDay = moment().startOf("day").add(1, "day"); // desde hoy
    const endDate = moment().startOf("day").add(15, "months"); // hasta 15 meses despues

    // Rates
    const rates = accommodation.Rates ?? [];
    for (const item of rates) {
      // Agregamos los dias de cambios
      const changeDays = item.MandatoryChangeDays ?? [];
      for (const mandatory of changeDays) {
        // Evaluamos que sea mayor al dia de mañana
        if (firstDay.isSameOrBefore(mandatory.ChangeDates.End)) {
          const begin = mandatory.ChangeDates.Begin?.substring(0, 10) ?? "";
          const end = mandatory.ChangeDates.End?.substring(0, 10) ?? "";
          this._fillDays(mappedDays, {
            begin,
            end,
            type: "changeDay",
            onCompleteDay: (day) => {
              day.changeDay = mandatory.ChangeDay;
              return day;
            },
          });
        }
      }
      // Agregamos los precios -- la clave pkey es la fecha en ISOstring
      for (const pkey in item.Prices) {
        // console.log(pkey, " | ", item.Prices[pkey].Price);
        const key = pkey.substring(0, 10);
        if (!mappedDays[key]) {
          mappedDays[key] = {};
        }
        mappedDays[key].price = item.Prices[pkey].Price.Value;
        mappedDays[key].minimumStay = item.Prices[pkey].MinimumStay;
      }
    }
    // On demands periods
    const onDemandPeriods = occupancyCalendar.OnDemandPeriods ?? [];
    for (const item of onDemandPeriods) {
      // Evaluamos que sea mayor al dia de mañana
      if (firstDay.isSameOrBefore(item.Dates.End)) {
        let changeBegin = moment(item.Dates.Begin);
        let begin = changeBegin.isBefore(firstDay)
          ? firstDay.toISOString(true)
          : changeBegin.toISOString(true);
        let changeEnd = moment(item.Dates.End);

        let end = changeEnd.isAfter(endDate)
          ? endDate.toISOString(true)
          : changeEnd.toISOString(true);
        // Rellenamos los dias
        this._fillDays(mappedDays, {
          begin,
          end,
          type: "onDemandPeriods",
        });
      }
    }
    // Reservaciones
    const reservations = occupancyCalendar.Reservations ?? [];
    for (const item of reservations) {
      // Evaluamos que sea mayor al dia de mañana
      if (firstDay.isSameOrBefore(item.Dates.End)) {
        const begin = item.Dates.Begin?.substring(0, 10) ?? "";
        const end = item.Dates.End?.substring(0, 10) ?? "";
        // Rellenamos los dias
        this._fillDays(mappedDays, {
          begin,
          end,
          type: "reservation",
        });
      }
    }
    return mappedDays;
  }
  /**
   * Rellenamos los dias con la informacion necesaria
   */
  private _fillDays(
    map: IMapDays,
    params: {
      begin: string;
      end: string;
      type: MapDayType;
      onCompleteDay?: (day: IMapDay) => IMapDay;
    }
  ) {
    const { begin, end, type, onCompleteDay } = params;
    const beginDate = moment(begin);
    const endDate = moment(end);
    const daysDiff = endDate.diff(beginDate, "day");
    for (let indexDay = 0; indexDay <= daysDiff; indexDay++) {
      const date = beginDate.clone().add(indexDay, "day");
      const key = date.toISOString(true).substring(0, 10);
      // Si no existe la entrada, la creamos
      if (!map[key]) map[key] = {};
      // Si no corresponde al ultimo dia, es un arrivo
      if (indexDay < daysDiff) map[key].arrival = { type, end };
      // Si no corresponde al primer dia, es una salida
      if (indexDay > 0) map[key].departure = { type, begin };
      // Ejecutamos el callback para rellenar informacion extra al dia
      if (onCompleteDay) map[key] = onCompleteDay(map[key]);
    }
  }

  private async _getAccommdationDetail(params: {
    accommodationId: number;
    options?: IAccommodationOptions;
  }) {
    const { accommodationId, options } = params;
    try {
      const res = await irentAPI.post<{
        Accommodation: IAccommodation;
        PriceBreakdown: IPriceBreakDown;
        Status: number;
      }>("v1/Accommodation/Details", {
        AccommodationId: accommodationId,
        AccommodationOptions: options ?? {},
        Currency: authStore.currency,
        Guests: null,
        IncludePrices: "Futures",
        Languages: [authStore.language],
        WebsiteId: authStore.websiteId,
        Dates:
          this.datesSearchLocal.Begin && this.datesSearchLocal.Begin != "null"
            ? this.datesSearchLocal
            : null,
      });
      if (res.data.PriceBreakdown != undefined) {
        res.data.Accommodation.TotalRental =
          res.data.PriceBreakdown.TotalRental;
        this.priceBreakDown = res.data.PriceBreakdown;
      }
      res.data.Accommodation.AvailabilityStatus = res.data.Status;
      return res.data.Accommodation;
    } catch (err) {
      console.warn("[POST][ACCOMMODATION_DETAIL]: ", err);
      return null;
    }
  }

  private async _getOccupancyCalendar(params: {
    accommodationIds: number[];
    occupancyCalendarOptions: {
      AccommodationDetails: boolean;
      AgentDetails: boolean;
      ReferrerDetails: boolean;
      TenantDetails: boolean;
      BillToDetails: boolean;
      ExtrasDetails: boolean;
    };
  }) {
    const { accommodationIds, occupancyCalendarOptions } = params;
    try {
      const res = await irentAPI.post<IOccupancyCalendar>(
        "/v1/Accommodation/GetOccupancyCalendar",
        {
          AccommodationsIds: accommodationIds,
          OccupancyCalendarOptions: occupancyCalendarOptions,
          WebsiteId: authStore.websiteId,
        }
      );
      return res.data;
    } catch (err) {
      console.warn("[POST][OCCUPANCY_CALENDAR]: ", err);
      return null;
    }
  }

  private async _getPriceBreakDown(params: {
    AccommodationId: number;
    Guests: number;
    Dates: IDates;
  }) {
    const { AccommodationId, Guests, Dates } = params;
    try {
      const res = await irentAPI.post<IPriceBreakDown>(
        "/v1/Accommodation/PriceBreakDown",
        {
          AccommodationId,
          Guests,
          Dates,
          Languages: [authStore.language],
          Currency: authStore.currency,
          WebsiteId: authStore.websiteId,
        }
      );
      return res.data;
    } catch (err) {
      console.warn("[POST][PRICE_BREAK_DOWN]", err);
      return null;
    }
  }

  toListResults() {
    window.history.replaceState({}, "List Result", window.location.pathname);
    this.$common.to("ListResults");
    this.$vuetify.goTo(listStore.listScrollTop);
  }

  onScroll(e: { target: { scrollTop: any } }): void {
    if (typeof window === "undefined") return;
    let top = window.pageYOffset || e.target.scrollTop || 0;
    this.fab = top > 75;
  }
  copyLink() {
    navigator.clipboard.writeText(this.$refs["linkUrl"]?.value);
    this.snackbarText = this.$t("detail.copied");
    this.snackbar = true;
    this.shareDialog = false;
  }

  get pageLoaded() {
    return this.accommodation != null && this.days != null;
  }

  get websiteName() {
    return authStore ? authStore?.administrator?.Agent.Name : "";
  }
  get pageTitle() {
    return this.accommodation
      ? `${this.accommodation.Name} ${this.$common.capitalize(
          this.$tc(
            `listResults.gridList.accommodationsType.${this.accommodation.Type}`,
            1
          )
        )} in ${this.accoLocationString}`
      : this.websiteName;
  }
  get bedroomsCount() {
    return (
      this.accommodation?.Floors?.map(
        (floor) => floor.BedRooms?.length ?? 0
      ).reduce((a, b) => a + b, 0) ?? 0
    );
  }

  get bathroomsCount() {
    return (
      this.accommodation?.Floors?.map(
        (floor) => floor.BathRooms?.length ?? 0
      ).reduce((a, b) => a + b, 0) ?? 0
    );
  }
}
